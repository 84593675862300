<template>
  <el-card class="iot-person public-page-style iot-search-header">
    <div slot="header">
      <el-form :inline="true" :model="getForm">
        <el-form-item>
          <el-input
            size="small"
            clearable
            v-model="getForm.mqttName"
            placeholder="请输入MQTT名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            size="small"
            clearable
            v-model="getForm.mqttIp"
            placeholder="请输入MQTT编号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" plain @click="checkTable()"
            >查询</el-button
          >
        </el-form-item>
        <el-button
          type="primary"
          plain
          size="mini"
          class="iot-account-create"
          @click="addMqtt()"
          >新增</el-button
        >
      </el-form>
    </div>
    <div style="width:100%;height:678px;overflow-y: auto;">
    <el-table
      :data="tableData"
      stripe
      border=""
      style="width: 100%"
      :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
    >
      <el-table-column prop="mqttId" label="MQTT编号"></el-table-column>
      <el-table-column prop="mqttName" label="MQTT名称"></el-table-column>
      <el-table-column prop="mqttTag" label="MQTT标识"></el-table-column>
      <el-table-column label="操作" >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            plain
            @click="editMqtt(scope.row.mqttId)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            plain
            @click="delMqtt(scope.row.mqttId)"
            >删除</el-button
          >
        </template>
      </el-table-column>
 

    </el-table>
    </div>
     <div class="page">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
        :total="total"
        :page-size="getForm.size"
        layout="total, sizes, prev, pager, next, jumper"
        @prev-click="
          () => {
            $set(getForm, 'current', getForm.current - 1);
            getList();
          }
        "
        @next-click="
          () => {
            $set(getForm, 'current', getForm.current + 1);
            getList();
          }
        "
      >
      </el-pagination>
    </div>
    <el-dialog
    top="3%"
      :title="formTitle"
      :show-close="false"
      :visible.sync="mqttDialog"
      :before-close="handleClose"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
      width="30%"
      class="iot-mqtt"
    >
      <el-form
        :model="mqttForm"
        ref="mqttRef"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="MQTT名称" prop="mqttName">
          <el-input
            v-model="mqttForm.mqttName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="MQTT标识" prop="mqttTag">
          <el-input v-model="mqttForm.mqttTag" autocomplete="off"></el-input>
        </el-form-item>
        <!-- <el-form-item label="服务器端口" prop="mqttPort">
          <el-input
            v-model="mqttForm.mqttPort"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="mqttForm.remark" autocomplete="off"></el-input>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain size="mini" @click="submitForm()"
          >确 定</el-button
        >
        <el-button type="info" plain size="mini" @click="mqttDialog = false"
          >取 消</el-button
        >
        
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import { TABLE_HEADER_COLOR } from "../util/theme";
let that;
export default {
  name: "Mqtt",
  data() {
    return {
      theme: { TABLE_HEADER_COLOR },
      mqttDialog: false,
      tableData: [],
      getForm: {
        mqttName: "",
        mqttIp: "",
        size: 10,
        current: 1,
        total: 0,
      },
      mqttForm: {
        mqttId: "",
        mqttName: "",
        mqttIp: "",
        mqttPort: "",
        remark: "",
      },
      isCreate: true,
      formTitle: "",
      rules: {
        mqttName: [
          { required: true, message: "请输入服务器名称", trigger: "blur" },
        ],
        mqttIp: [
          { required: true, message: "请输入服务器IP", trigger: "blur" },
        ],
        mqttPort: [
          { required: true, message: "请输入服务器端口", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
        handleSizeChange(val) {
        that.getForm.size=val;
        that.getList();
      },
      handleCurrentChange(val) {
        that.getForm.current=val;
        that.getList();
      },
    submitForm() {
      that.$refs["mqttRef"].validate((valid) => {
        if (valid) {
          let _api = that.isCreate
            ? that.$api.mqtt.createMqtt(that.mqttForm)
            : that.$api.mqtt.editMqtt(that.mqttForm);
          _api.then((response) => {
            that.$refs.mqttRef.resetFields();
            that.mqttDialog = false;
            that.isCreate = true;
            that.getMqttPage();
            that.$message({
              type: "success",
              message: response.msg,
            });
          });
        }
      });
    },
    checkTable() {
      that.$set(that.getForm, "current", 1);
      that.getMqttPage();
    },
    getMqttPage() {
      that.$api.mqtt.getMqttPage(that.getForm).then((response) => {
        
        that.tableData = response.data.records;
        that.getForm.total = response.data.total;
      });
    },
    addMqtt() {
      that.formTitle = "新增MQTT";
      that.mqttDialog = true;
      that.isCreate = true;
      that.mqttForm =  {
        mqttId: "",
        mqttName: "",
        mqttIp: "",
        mqttPort: "",
        remark: "",
      };
    },
    handleClose() {
      that.mqttDialog = false;
    },
    editMqtt(mqttId) {
      that.$api.mqtt.getMqtt(mqttId).then((response) => {
        if (response.code == 200) {
          that.mqttForm = response.data;
          that.formTitle = "修改服务器";
          that.mqttDialog = true;
          that.isCreate = false;
        }
      });
    },
    delMqtt(mqttId) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$api.mqtt.delMqtt(mqttId).then((response) => {
            if (response.code == 200) {
              that.$message({
                type: "success",
                message: response.msg,
              });
              that.getMqttPage();
            }
          });
        });
    },
  },
  beforeCreate() {
    that = this;
  },
  mounted() {
    that.getMqttPage();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.iot-account-create {
  float: right;
  margin: 10px;
}
.iot-account-permission {
  height: 387px;
}
.iot-search-header >>> .el-card__header {
  padding: 0 18px;
}
.iot-person >>> .el-select {
  width: 100%;
}
.iot-mqtt >>> .el-dialog {
  height: 678px;
}
.page{
  display: flex;
  justify-content: flex-end;
}
</style>
