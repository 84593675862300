export const PRIMARY_COLOR = '#9EECFF';//主题色

export const MAIN_MENU_BACKGROUND_COLOR = "#4e73df";//主菜单背景色
export const MAIN_MENU_TEXT_COLOR = "#FFFFFF";//主菜单文字颜色
export const MAIN_MENU_ACTIVE_COLOR = "#FFE100";//主选中菜单的文字颜色
export const MAIN_HEAD_BACKGROUND_COLOR = "#FFFFFF"//框架头部背景色

export const SUBMENU_BACKGROUND_COLOR = "#FFFFFF";//二级菜单背景色
export const SUBMENU_TEXT_COLOR = "#707070";//二级菜单文字颜色
export const SUBMENU_ACTIVE_COLOR = "#4e73df";//二级选中菜单的文字颜色

export const TABLE_HEADER_COLOR = "#e7e7e7";//二级选中菜单的文字颜色

export default {
    PRIMARY_COLOR,
    MAIN_MENU_BACKGROUND_COLOR,
    MAIN_MENU_TEXT_COLOR,
    MAIN_MENU_ACTIVE_COLOR,
    MAIN_HEAD_BACKGROUND_COLOR,
    SUBMENU_BACKGROUND_COLOR,
    SUBMENU_TEXT_COLOR,
    SUBMENU_ACTIVE_COLOR,
    TABLE_HEADER_COLOR
}
